import cn from 'classnames';
import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

export const AppMenu: FC = () => {
  const history = useHistory();
  const [emirListVisible, setEmirListVisible] = useState(false);
  const [sftrListVisible, setSftrListVisible] = useState(false);

  const handleChangeEmirVisible = () => setEmirListVisible(!emirListVisible);
  const emirListClassnames = cn('mui-dropdown__menu', {
    'mui--is-open': emirListVisible,
  });

  const handleRouteOnMifid = () => history.push('/mifid/reports');
  const handleRouteOnDashboard = () => history.push('/dashboard');
  const handleRouteOnOffsets = () => history.push('/offsets');

  const handleChangeSftrVisible = () => setSftrListVisible(!sftrListVisible);
  const sftrListClassnames = cn('mui-dropdown__menu', {
    'mui--is-open': sftrListVisible,
  });

  return (
    <div className="mui-appbar mb-2">
      <div className="mui-container-fluid">
        <div className="mui-dropdown">
          <button
            className="mui-btn mui-btn--primary"
            onClick={handleChangeEmirVisible}
          >
            Emir
            <span className="mui-caret" />
          </button>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions  */}
          <ul className={emirListClassnames} onClick={handleChangeEmirVisible}>
            <li>
              <Link to="/emir/trades">Trades</Link>
            </li>
            <li>
              <Link to="/emir/positions">Positions</Link>
            </li>
            <li>
              <Link to="/emir/scripts">Scripts</Link>
            </li>
          </ul>
        </div>
        <div className="mui-dropdown">
          <button
            className="mui-btn mui-btn--primary"
            onClick={handleChangeSftrVisible}
          >
            Sftr
            <span className="mui-caret" />
          </button>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions  */}
          <ul className={sftrListClassnames} onClick={handleChangeSftrVisible}>
            <li>
              <Link to="/sftr/trades">Trades</Link>
            </li>
            <li>
              <Link to="/sftr/reports">Reports</Link>
            </li>
          </ul>
        </div>
        <button
          className="mui-btn mui-btn--primary"
          onClick={handleRouteOnMifid}
        >
          Mifid
        </button>
        <button
          className="mui-btn mui-btn--primary"
          onClick={handleRouteOnDashboard}
        >
          Dashboard
        </button>
        <button
          className="mui-btn mui-btn--primary"
          onClick={handleRouteOnOffsets}
        >
          Offsets
        </button>
        <div className="mui-dropdown">
          <p className="nav-link">
            Reporting version:
            <b> {process.env.REACT_APP_VERSION}</b>
          </p>
        </div>
      </div>
    </div>
  );
};
