import dayjs from 'dayjs';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Table,
  Panel,
  Tabs,
  Tab,
  Select,
  styled,
  CalendarDatePicker,
  OnSaveEditableRow,
  Notification,
  ICellValue,
  IRow,
  useTableData,
  IOnFetchArguments,
  FileCsvIcon,
  DeleteIcon,
  UploadIcon,
  DownloadIcon,
  TCommonParams,
  ExportTableParams,
} from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { defaultOrdering, pageSizes } from 'constants/tables';
import {
  Offset,
  Adjustment,
  OffsetsApiService,
  AddAdjustmentPayload,
  Discrepancy,
} from 'services/offsets';
import { theme } from 'theme';
import { TableDataWithPagination } from 'types';
import { calculateCountOfPages, getWorkYesterday } from 'utils';

import { AddAdjustment } from './AddAdjustment';
import { useLists } from './hooks';
import {
  getOffsetsColumns,
  getAdjustmentColumns,
  getActivityColumns,
  getDiscrepanciesColumns,
} from './utils';

export const ActionsDiv = styled('div')(() => ({
  width: '240px',
  marginRight: '20px',
}));

const api = new OffsetsApiService();

export const Offsets: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dateFilter, setDateFilter] = useState(getWorkYesterday());
  const [legalEntity, setLegalEntity] = useState<string | undefined>();
  const [counterparty, setCounterparty] = useState<string | undefined>();
  const [counterpartyAccount, setCounterpartyAccount] = useState<
    string | undefined
  >();
  const [addIsOpened, setAddIsOpened] = useState(false);

  const { cpOptions, leOptions, cpAccountOptions } = useLists();
  const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSetLegalEntity = (event: ChangeEvent<HTMLInputElement>) => {
    setLegalEntity(event.target.value);
  };
  const handleSetCounterparty = (event: ChangeEvent<HTMLInputElement>) => {
    setCounterparty(event.target.value);
  };

  const handleSetCounterpartyAccount = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setCounterpartyAccount(event.target.value);
  };

  const handleChangeDate = useCallback(
    (date: Date | null) => {
      setDateFilter(dayjs(date).format(DATE_FORMAT));
    },
    [setDateFilter],
  );

  const getTableData = useCallback(
    (props: IOnFetchArguments) => {
      const params = {
        ...props.params,
        counterparty_account_id:
          counterpartyAccount === 'null' ? undefined : counterpartyAccount,
        counterparty: counterparty === 'null' ? undefined : counterparty,
        legal_entity: legalEntity === 'null' ? undefined : legalEntity,
        report_date: dateFilter,
      };
      if (selectedTab === 3) {
        return api.getDiscrepancies({
          ...props,
          params: { ...params, date: dateFilter },
        });
      }
      if (selectedTab === 2) {
        return api.getActivity({
          ...props,
          params,
        });
      }
      if (selectedTab === 1) {
        return api.getAdjustments({
          ...props,
          params,
        });
      }
      return api.getOffsets({
        ...props,
        params,
      });
    },
    [dateFilter, selectedTab, legalEntity, counterpartyAccount, counterparty],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
    }),
    [getTableData],
  );

  const {
    data,
    isLoading,
    resetFilters,
    filters,
    removeFilter,
    setFilter,
    limit,
    page,
    setPage,
    setLimit,
    fetchData,
  } = useTableData<TableDataWithPagination<any>>(tableData);

  const filterProps = useMemo(
    () => ({
      filters,
      removeAllFilters: resetFilters,
    }),
    [resetFilters, filters],
  );

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const fetchReportFile = useCallback(async (id: number) => {
    const response = await fetch(
      `https://offset-management-stage.exante.eu/api/v1/reports/download_report/${id}`,
      {
        headers: api.getBaseHeaders(),
      },
    );
    const fileName =
      response.headers.get('Content-Disposition') ??
      '/offsets/XNT_Reporting_2024-01-01.csv';
    const result = await response.blob();
    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
    document.body.removeChild(aElement);
  }, []);

  const columns = useMemo(() => {
    if (selectedTab === 3) {
      return getDiscrepanciesColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
      });
    }
    if (selectedTab === 2) {
      return getActivityColumns();
    }
    if (selectedTab === 1) {
      return getAdjustmentColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        cpOptions,
        cpAccountOptions,
        leOptions,
      });
    }
    return getOffsetsColumns({ onFilter: setFilter, onRemove: removeFilter });
  }, [cpOptions, leOptions, leOptions, setFilter, removeFilter, selectedTab]);

  const tableTitle = (
    <div className="d-flex">
      <ActionsDiv>
        <Select
          label="Legal Entity"
          fullWidth
          value={legalEntity}
          onChange={handleSetLegalEntity}
          options={[{ label: 'All', value: 'null' }, ...leOptions]}
        />
      </ActionsDiv>
      <ActionsDiv>
        <Select
          label="Counterparty"
          fullWidth
          value={counterparty}
          onChange={handleSetCounterparty}
          options={[{ label: 'All', value: 'null' }, ...cpOptions]}
        />
      </ActionsDiv>
      {selectedTab !== 2 && (
        <ActionsDiv>
          <Select
            label="Counterparty Account"
            fullWidth
            value={counterpartyAccount}
            onChange={handleSetCounterpartyAccount}
            options={[{ label: 'All', value: 'null' }, ...cpAccountOptions]}
          />
        </ActionsDiv>
      )}
    </div>
  );

  const handleSaveEditableRow: OnSaveEditableRow<Adjustment | Offset> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      const payload: AddAdjustmentPayload = {
        symbol_id: updatedValues.symbol_id ?? '',
        report_date: dayjs(updatedValues.report_date).format(DATE_FORMAT),
        account_id: updatedValues.account_id ?? '',
        counterparty_account_id: updatedValues.counterparty_account_id ?? '',
        counterparty: updatedValues.counterparty ?? '',
        legal_entity: updatedValues.legal_entity ?? '',
        sold: updatedValues.sold,
        bought: updatedValues.bought,
      };
      await api.updateAdjustment(previousValues.id, payload);
      Notification.success({
        title: 'Offset Adjustment was updated successfully',
      });
      fetchData();
    } catch (error) {
      Notification.error({
        title: 'Update error',
        description: String(error),
      });
    }
  };

  const handleDeleteAdjustment = async (payload: Adjustment) => {
    try {
      await api.deleteAdjustment(payload.id);
      Notification.success({
        title: 'Adjustment was deleted successfully',
      });
      fetchData();
    } catch (error) {
      Notification.error({
        title: 'Delete error',
        description: String(error),
      });
    }
  };

  const getRowProps = useCallback(
    (row: IRow<Offset | Adjustment>) => {
      const offsetColor =
        Number((row.original as Offset)?.position_after_gs_diff ?? '') === 0
          ? theme?.color.table.bg.source
          : theme?.color.table.bg.radical;
      return {
        style: {
          background:
            selectedTab === 0 ? offsetColor : theme?.color.table.bg.basic2,
        },
      };
    },
    [selectedTab],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<Offset | Adjustment>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );
  const handleChangeFileInput: ChangeEventHandler<HTMLInputElement> = async (
    value,
  ) => {
    if (value.target.files) {
      try {
        await api.uploadAdjustments(value.target.files);
        Notification.success({
          title: 'Items were successfully uploaded',
        });
        fetchData();
      } catch (error) {
        Notification.error({
          title: 'Upload items error',
          description: String(error),
        });
      }
    }
  };

  const additionalActions = useMemo(() => {
    const actions = [
      {
        title: 'Set report date',
        component: (
          <div className="d-flex align-end">
            <span className="mr-2">{dateFilter}</span>
            <CalendarDatePicker
              disableFuture
              onChange={handleChangeDate}
              value={new Date(dateFilter)}
            />
          </div>
        ),
      },
    ];
    if (selectedTab === 1) {
      actions.push({
        title: 'Upload file',
        component: (
          <div>
            <input
              type="file"
              id="csvfile"
              style={{ display: 'none' }}
              onChange={handleChangeFileInput}
            />
            <UploadIcon
              style={{ cursor: 'pointer' }}
              size={24}
              onClick={() => {
                document.getElementById('csvfile')?.click();
              }}
            />
          </div>
        ),
      });
    }
    return actions;
  }, [selectedTab, dateFilter, handleChangeDate, fetchData]);

  const rowActions = useMemo(() => {
    if (selectedTab === 1) {
      return [
        {
          width: 140,
          label: <DeleteIcon size={16} />,
          onClick: (value: Adjustment) => {
            handleDeleteAdjustment(value);
          },
          title: 'Delete adjustment',
        },
      ];
    }

    return [
      {
        width: 140,
        label: <DownloadIcon size={16} />,
        disabled: true,
        onClick: (value: any) => {
          fetchReportFile(value.id);
        },
        title: 'Downloading is not available',
      },
    ];
  }, [selectedTab, fetchReportFile, handleDeleteAdjustment]);

  const exportTableParams = useMemo(
    () => ({
      icon: <FileCsvIcon size={24} />,
      type: 'server',
      onFetch: async (params: TCommonParams) => {
        return (
          await getTableData({
            paginationParams: {
              page: 0,
              limit: data?.pagination.total ?? 0,
              skip: 0,
            },
            sortingParams: params.sorting,
            filtersParams: filters,
            params: {
              page: 0,
              limit: data?.pagination.total ?? 0,
              skip: 0,
              sorting: params.sorting,
            },
          })
        ).items;
      },
    }),
    [filters, data?.pagination.total],
  );

  return (
    <div className="mui-row mt-2">
      <div className="mui-col-md-12">
        <Panel title="Offset Management" className="pb-0">
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label="Offsets" />
            <Tab label="Offsets Adjustments" />
            <Tab label="Activity" />
            <Tab label="Discrepancies" />
          </Tabs>
        </Panel>
        <Table
          tableId="offsetsTable"
          title={tableTitle}
          onAdd={selectedTab === 1 ? () => setAddIsOpened(true) : undefined}
          exportTableParams={
            exportTableParams as ExportTableParams<Discrepancy>
          }
          additionalActions={additionalActions}
          rowActions={{
            show: [1, 2].includes(selectedTab),
            isEditedRow: () => selectedTab === 1,
            onSave: handleSaveEditableRow,
            onCancel: async () => {},
            additionalActions: rowActions,
          }}
          data={data?.items || []}
          columns={columns}
          hasFilters
          filteringProps={filterProps}
          defaultSortBy={defaultOrdering}
          isLoading={isLoading}
          getRowProps={getRowProps}
          getCellProps={getCellProps}
          showScrollbar
          hasPagination
          showTableInfo
          manualSortBy
          isFlexLayout
          pageSize={10}
          pageSizes={pageSizes}
          serverPaginationProps={{
            pageIndex: page,
            pageCount,
            pageSize: limit,
            total: data?.pagination.total || 0,
            setPage,
            setPageSize: setLimit,
          }}
        />
      </div>
      {addIsOpened && (
        <AddAdjustment
          leOptions={leOptions}
          cpOptions={cpOptions}
          cpAccountOptions={cpAccountOptions}
          onClose={() => setAddIsOpened(false)}
          reloadData={fetchData}
        />
      )}
    </div>
  );
};
