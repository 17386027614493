import { useEffect, useState } from 'react';

import { OffsetsApiService } from 'services/offsets';
import { getSelectOptions } from 'utils';

const api = new OffsetsApiService();

export function useLists() {
  const [cpList, setCpList] = useState<string[]>([]);
  const [cpAccountList, setCpAccountList] = useState<string[]>([]);
  const [leList, setLeList] = useState<string[]>([]);

  const getLists = async () => {
    const cpResponse = await api.getCpList();
    setCpList(cpResponse);
    const cpAccountResponse = await api.getCpAccountList();
    setCpAccountList(cpAccountResponse);
    const leResponse = await api.getLeList();
    setLeList(leResponse);
  };

  useEffect(() => {
    getLists();
  }, []);

  return {
    cpOptions: getSelectOptions(cpList),
    leOptions: getSelectOptions(leList),
    cpAccountOptions: getSelectOptions(cpAccountList),
  };
}
