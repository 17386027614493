import * as cookies from 'js-cookie';

import {
  SESSION_ID_RUN_UI_REPORTING_KEY,
  SESSION_ID_HEADER_KEY,
  ACCESS_TOKEN_COOKIE_KEY,
  AUTHORIZATION_HEADER_KEY,
} from 'constants/session';

export type TAccessToken = {
  id_token: string;
  expires_in: number;
  access_token: string;
};
class ApiService {
  public _apiBase = '';

  getBaseHeaders = () => {
    const headers: Record<string, string> = {};
    const reportingSessionId =
      cookies.get(SESSION_ID_RUN_UI_REPORTING_KEY) ?? '';
    let authToken = '';

    const googleAuthCookie = cookies.get(ACCESS_TOKEN_COOKIE_KEY);
    if (googleAuthCookie) {
      try {
        const { access_token: token }: TAccessToken = JSON.parse(
          cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? '',
        );
        authToken = token;
      } catch (error) {
        console.error(`Access token error: ${error}`);
      }
    }

    if (reportingSessionId) {
      headers[SESSION_ID_HEADER_KEY] = reportingSessionId;
    }
    if (authToken) {
      headers[AUTHORIZATION_HEADER_KEY] = `Bearer ${authToken}`;
    }
    return headers;
  };

  async getResource<R>(url: string): Promise<R> {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'GET',
      headers: this.getBaseHeaders(),
    });
    if (!res.ok) {
      throw new Error(`Can't fetch from ${url}. Got: ${res.status}`);
    }
    return res.json();
  }

  async postResource<T, R>(url: string, payload?: T): Promise<R> {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res.json();
  }

  async postFormData<R>(url: string, formData: FormData): Promise<R> {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'POST',
      body: formData,
      headers: {
        accept: 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    if (!res.ok) {
      throw new Error(`Post failed: ${res.status}`);
    }

    return res.json();
  }

  async patch<T, R>(url: string, payload: T): Promise<R> {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res.json();
  }

  async put<T, R>(url: string, payload: T): Promise<R> {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res.json();
  }

  async delete(url: string) {
    const res = await fetch(`${this._apiBase}${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...this.getBaseHeaders(),
      },
    });

    if (!res.ok) {
      throw new Error(`Update failed: ${res.status}`);
    }

    return res;
  }
}

export default ApiService;
