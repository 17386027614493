import { parseAsync } from 'json2csv';
import { useState } from 'react';

import { ExportIcon } from '../../../Icons';
import { useTranslator } from '../../../contexts/TranslationContext';
import { ActionButton } from '../ActionButton/ActionButton';
import { ExportButtonProps, ServerExport } from '../types';

import {
  prepareFields,
  getFieldsListFromColumns,
  initDownload,
  prepareSorting,
  callFnSafely,
} from './helpers';

export const ExportButton = <Data extends object>({
  title,
  tableColumns,
  visibleColumnKeys,
  exportTableParams,
  data: staticData,
  filters,
  sorting,
  total,
}: ExportButtonProps<Data>) => {
  const { t } = useTranslator();

  const [isLoading, setLoading] = useState(false);
  const isServerExport = exportTableParams?.type === 'server';

  const createUrl = async (rows: Data[]) => {
    const fields = getFieldsListFromColumns<Data>(
      tableColumns,
      visibleColumnKeys,
    );
    const preparedFields = prepareFields<Data>(
      fields,
      tableColumns,
      exportTableParams?.excludedColumns,
    );
    const csv = await parseAsync(rows, { fields: preparedFields });
    const blob = new Blob([csv], { type: 'text/csv' });

    return URL.createObjectURL(blob);
  };

  const handleClientExport = async () => {
    const preparedStaticRows = staticData.map(({ values }) => values) as Data[];
    const urlObject = await createUrl(preparedStaticRows);
    initDownload(urlObject, title);
  };

  const handleServerExport = async () => {
    if (sorting) {
      const preparedSorting = prepareSorting<Data>(sorting);
      const data = await (exportTableParams as ServerExport<Data>).onFetch({
        ...filters,
        sorting: preparedSorting,
        limit: total,
      });
      const urlObject = await createUrl(data);
      initDownload(urlObject, title);
    }
  };

  const handleExport = async () => {
    const delayTm = 400;
    let finished = false;
    let loadingDelayIsOver = false;

    setTimeout(() => {
      loadingDelayIsOver = true;
      if (finished) {
        setLoading(false);
      }
    }, delayTm);
    try {
      setLoading(true);
      if (isServerExport) {
        await handleServerExport();
      } else {
        await handleClientExport();
      }

      callFnSafely(exportTableParams?.onSuccess);
    } catch (error) {
      const errorHasBeenHandled = callFnSafely(
        exportTableParams?.onError,
        error,
      );
      if (!errorHasBeenHandled) {
        throw error;
      }
    } finally {
      finished = true;
      if (loadingDelayIsOver) {
        setLoading((prev) => !prev);
      }
    }
  };

  const isDisabled =
    isLoading || !total || !staticData.length || !visibleColumnKeys.length;

  return (
    <ActionButton
      title={t('ui__table__actions__download_csv')}
      onClick={handleExport}
      disabled={isDisabled}
      loading={isLoading}
    >
      {exportTableParams?.icon ?? <ExportIcon size={24} />}
    </ActionButton>
  );
};
